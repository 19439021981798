@use "css" as *;

.shaftButtons {
  width: 14%;
  height: 100%;
  @include fCenter(column-reverse);

  button {
    width: 100%;
    height: 100%;
    margin: 1px;
    outline: none;
    border: none;
    background-color: $btnColor;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: clamp(0.8rem, 2vw, 1.5rem);
    border-radius: 2px;
    @include centered;
    transition: all 200ms ease;

    &:hover {
      color: rgb(11, 35, 36);
      background-color: darken($btnColor, 15%);
    }

    &.active {
      color: white;
    }
  }
}
.movingLiftStyle {
  width: 20%;
  aspect-ratio: 1;
  flex: 0 1 auto;
  border-radius: 50%;
  filter: blur(2px);
  animation: blink 150ms ease-in-out infinite alternate-reverse;

  @keyframes blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  background-color: red;
}

.inactive {
  color: rgb(24, 92, 45);
}

.active {
  color: white;
}
