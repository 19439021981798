@use "css" as *;
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap");

$distance: 35px;
$bitDim: 40px;

@mixin bitDimension {
  width: 180px;
  height: $bitDim;
}

.digitContainer {
  @include landScape;
  background: rgb(27, 27, 27);
  margin: 10vh auto;
}

.container {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  @include fCenter(row);
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;

  &::before {
    position: absolute;
    content: "";
    background: url(../../images/w2.jpg);
    background-size: cover;
    background-position: center center;
    left: 0;
    top: 0;
    width: 105%;
    height: 100%;
    opacity: 0.2;
    filter: blur(1px) grayscale(50%);
    transform: translateX(-5%);
    animation: hoverOver 12s linear infinite alternate-reverse;

    &:focus {
      border: none;
      outline: none;
    }
  }

  @keyframes hoverOver {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-5%);
    }
  }
}

.digit_box {
  position: relative;
  width: 250px;
  height: 500px;
  transform: skewX(-5deg);

  &::after {
    position: absolute;
    content: "";
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: rgba(255, 0, 0, 0.623);
    bottom: 2%;
    right: -20%;
    filter: blur(3px);
    animation: blink 500ms ease-in-out infinite alternate-reverse;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  & > div {
    background-color: rgba(193, 21, 21, 0.76);
    box-shadow: 2px 2px 10px rgba(255, 0, 0, 0.7);
    border-radius: 40%;
  }
}

.position_dimension {
  position: absolute;
  width: $bitDim;
  height: 40%;
}

.box0 {
  top: 0;
  left: $distance;
  @include bitDimension();
}

.box1 {
  top: 50%;
  transform: translateY(-50%);
  left: $distance;
  @include bitDimension();
}

.box2 {
  bottom: 0;
  left: $distance;
  @include bitDimension();
}

.box3 {
  top: $distance;
  left: 0;
}

.box4 {
  top: $distance;
  right: 0;
}

.box5 {
  bottom: $distance;
  left: 0;
}

.box6 {
  bottom: $distance;
  right: 0;
}
