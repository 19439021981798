@use "css" as *;

.fruitBox {
  background-color: cadetblue;
  height: 100vh;
  width: 100%;
  margin: auto;
  @include fCenter(column);
  overflow: hidden;

  img {
    max-width: 50%;
    box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
  }

  p {
    color: white;
    font-size: 1.7rem;
    padding: 1rem;
  }

  a {
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: monospace;
    color: rgb(34, 90, 92);
  }
}
