.quizBox {
    width: 50%;
    height: 40vh;
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    gap: 1rem;
    h1 {
        margin: 2rem;
        color: white;
        letter-spacing: 1px;
    }
    h2 {
        color: red;
        span {
            color: white;
            font-size: 1.3rem;
        }
    }
    span {
        color: red;
    }
}

.btnWrap {
    border-left: 2px solid white;
    padding-left: 0.5rem;
    display: flex;
    justify-content: space-around;
    align-items: left;
    flex-direction: column;
    button {
        outline: none;
        border: 3px solid rgba(68, 131, 226, 0.678);
        border-radius: 5px;
        background-color: transparent;
        text-align: left;
        padding: 0.5rem;
        color: white;
        font-size: 1.1rem;
        &:hover {
            background-color: rgba(0, 0, 0, 0.4);
            color: limegreen;
        }
        &:active {
            transform: scale(0.97);
        }
    }
}

.scoreStyle {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
        font-size: 3rem;
    }
    button {
        outline: none;
        border: 3px solid rgba(68, 131, 226, 0.678);
        border-radius: 5px;
        background-color: transparent;
        font-size: 2rem;
        padding: 0.5rem;
        color: white;
        &:hover {
            background-color: rgba(0, 0, 0, 0.4);
            color: limegreen;
        }
        &:active {
            transform: scale(0.97);
        }
    }
}

@media only screen and (max-width: 800px) {
    .quizBox {
        width: 95%;
        height: 50vh;
    }
    .scoreStyle {
        h1,
        button {
            font-size: 1.6rem;
        }
    }
}
