.homePage {
  margin: 10vh auto;
  padding: 1rem;
  width: 80%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.jokeBox {
  text-align: left;
  padding-left: 3px;
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.3rem;
}
