@use "css" as *;

.card_details {
  color: crimson;
  list-style: none;
  @include fBetween(row);
}

@media only screen and (max-width: 800px) {
  .card_details {
    @include fBetween(column);
    width: 50%;
    font-size: 0.6rem;

    span {
      font-size: 0.8rem;
      text-align: center;
    }
  }
}
