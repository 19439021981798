@use "css" as *;

.loader_bg {
  position: absolute;
  min-width: 80px;
  min-height: 30px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: grid;
  place-items: center;
}

.loader_dots {
  @include fAround(row);
  padding: 3px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 2px;
  background-color: rgba(232, 221, 221, 0.741);
  box-shadow: 2px 2px 5px rgba(255, 255, 255, 0.3);
  animation: blink 500ms ease infinite alternate-reverse;

  @keyframes blink {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
}
