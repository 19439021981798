@use "css" as *;

.flip_box {
  width: 100%;
  height: 100%;
  perspective: 1000px;
}

.inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;

  &.turn {
    transform: rotateY(180deg);
  }
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  @include fCenter(column);
}

.back {
  transform: rotateY(180deg);
}
