@mixin centered {
	display: grid;
	place-items: center;
}

@mixin flexWrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center; //child is flex: 1 1 <baseWidth>   or 0 1 <baseWidth> stand for flexgrow, flexshrink, and flex-basis
}

@mixin sideBar {
	display: grid;
	grid-template-columns: minmax(150px, 25%), 1fr;
}

@mixin headerMainFooter {
	display: grid;
	grid-template-rows: auto 1fr auto;
}

@mixin headFooterAndBetween {
	display: grid;
	grid-template: auto 1fr auto/auto 1fr auto;
	// header grcol 1/4   left-side grcol 1/2  main grcol 2/3  right-side 3/4  footer grcol 1/4
}

@mixin twelveColumnsGrid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	// children displayed with grid-column ex. 1/7, 2/5 etc.
}

@mixin RAM($min, $max, $gap) {
	display: grid;
	grid-gap: $gap;
	grid-template-columns: repeat(auto-fit, minmax($min, $max));
	// grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	// with auto-fill baseline does not increase !
}

@mixin lineUp($rows, $gap) {
	height: auto;
	display: grid;
	grid-gap: $gap;
	grid-template-columns: repeat($rows, 1fr);
	// child : justify-content : space-between;
	// flex-direction: column;
}

@mixin textStyle {
	font-size: 25px;
	font-weight: bold;
	color: rgb(19, 6, 34);
}

@mixin fAround($direction) {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: $direction;
}

@mixin fCenter($direction) {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: $direction;
}

@mixin fEven($direction) {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: $direction;
}

@mixin fBetween($direction) {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: $direction;
}

@mixin fixedDiv {
	position: fixed;
	width: 100%;
	height: 100vh;
	left: 0px;
	top: 0px;
	background-color: rgba(0, 0, 0, 0.9);
	@include centered();
}

@mixin success {
	color: green;
	padding: 12px;
	border: $green;
	font-weight: bold;
	width: 100px;
}

@mixin error {
	color: red;
	padding: 12px;
	border: $red;
	font-weight: bold;
	width: 100px;
}

@mixin nightMode {
	background-color: rgba(14, 13, 13, 0.8);
	color: whitesmoke;
}

@mixin absoluteMiddle {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

@mixin landScape {
	width: 90%;
	height: 80vh;
	@include centered();
	background: rgba(128, 128, 128, 0.2);
	box-shadow: 1px 1px 4px rgba(128, 128, 128, 0.5);
	transition: box-shadow 200ms ease;
	&:hover {
		box-shadow: 3px 3px 10px rgba(128, 128, 128, 0.8);
	}
}

@mixin shadow {
	transition: box-shadow 100ms ease;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3), -2px -2px 4px rgba(0, 0, 0, 0.3);
	&:hover {
		box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
	}
}

@mixin shadow2 {
	box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.35);
	// box-shadow: inset 0 5px 15px  rgba(0,0,0,0.5);
	&:hover {
		box-shadow: 0 15px 70px 0 rgba(0, 0, 0, 0.35);
		transform: scale(1.001);
		transition: transform 200ms, box-shadow 200ms;
	}
}
