@use "../../../css/variables.scss" as *;
@use "../../../css/mixins.scss" as *;

$primaryColor: cadetblue;

.liftStyle {
	width: 40%;
	border-radius: 5px;
	display: flex;
	flex-direction: column-reverse;
	background-color: darken($primaryColor, 10%);
	overflow-y: scroll;
	&::-webkit-scrollbar {
		display: none;
	}
	button {
		outline: none;
		border: none;
		font-size: 2.1rem;
		background-color: darken($primaryColor, 30%);
		color: $primaryColor;
		font-family: monospace;
		&:hover {
			color: lighten($primaryColor, 30%);
		}
		&.active {
			color: white;
		}
	}
}

@for $i from 0 through 12 {
	.transform-#{$i} {
		transform: translateY(-$i * 100%);
	}
}

@for $i from 0 through 10000 {
	.transition-#{$i} {
		transition: transform #{$i}ms ease-in-out;
	}
}

@for $i from 1 through 20 {
	.height-#{$i} {
		height: #{$i}vh;
	}
}
