@use "css" as *;

.businessCard {
  box-shadow: $shadow;
  padding: 0.3rem;
  min-height: 40vh;
  @include fEven(column);
  border-radius: 10px;
  user-select: none;
  transition: transform 250ms;

  .imgStyle {
    max-width: 50%;
    margin: 0 auto;
  }

  ul {
    width: 100%;
    box-shadow: $shadow;
    padding: 3px;
    border-radius: 10px;
    transition: all 100ms ease-in-out;

    &:hover {
      transform: translateZ(10px);
    }
  }

  span {
    color: rgb(39, 94, 107);
    text-transform: uppercase;
    word-wrap: break-word;
  }
}

@media only screen and (max-width: 800px) {
  .businessCard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    p {
      font-size: 0.5rem;
    }

    span {
      font-size: 1.2rem;
    }

    ul {
      width: 40%;
      @include centered;
    }

    .imgStyle {
      max-width: 35%;
      margin: 0 auto;
    }
  }
}
