@use "../../css/variables.scss" as *;
@use "../../css/mixins.scss" as *;

.container {
	width: 100%;
	min-width: 100%;
	min-height: 100vh;
	@include fBetween(row);
	flex-wrap: wrap;
	padding: 1rem;
	background-image: url("../../images/city800.jpg");
}

.block {
	position: relative;
	display: grid;
	place-items: center;
	outline: 5px solid $primaryColor;
}

.control_panel {
	@include centered;
	height: 50vh;
	width: 20%;
}

.state_panel {
	@include centered;
	height: 50vh;
	width: 20%;
}

.leftSide {
	left: 1%;
	position: absolute;
	bottom: 0;
}
.rightSide {
	right: 1%;
	position: absolute;
	bottom: 0;
}

@for $i from 1 through 100 {
	.width-#{$i} {
		width: #{$i}vw;
	}
}

@media only screen and (max-width: 450px) {
	.container {
		@include fEven(column);
	}
	.control_panel {
		width: 80%;
		margin: 2rem auto;
	}
	.state_panel {
		width: 80%;
		margin: 2rem auto;
	}
}
