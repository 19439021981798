.black_back {
	width: 100%;
	height: 100vh;
	background-color: rgb(19, 18, 18);
	display: flex;
	justify-content: center;
	align-items: center;
}
.button_styles {
	padding: 10px 15px;
	outline: none;
	cursor: pointer;
	border: 3px solid #03e9f4;
	border-radius: 10px;
	background-color: transparent;
	box-shadow: 0 0 5px #03e8f4a4, inset 0 0 0px #03e8f475;
	overflow: hidden;
	transition: all 50ms ease;
	&:hover {
		box-shadow: 0 0 15px 7px #03e8f4a4, inset 0 0 10px 10px #03e8f475;
	}
	&:active {
		span {
			color: crimson;
		}
		transform: scale(0.98);
	}
	span {
		color: #03e9f4;
		font-size: 1.2rem;
		text-transform: uppercase;
	}
}
