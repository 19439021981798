$rubikSide: 250px;

.rubikContainer {
  width: 100%;
  height: 100vh;
  background-color: rgba(14, 13, 13, 0.904);
  perspective: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.rubikWrapper {
  position: relative;
  width: $rubikSide;
  height: $rubikSide;
  transform-style: preserve-3d;
  transition: transform 1200ms ease;
}

.rubikSide {
  position: absolute;
  background-color: transparent;
  border: 1px solid white;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

.buttonsWrapper {
  display: flex;
  margin-bottom: 3rem;

  & > button {
    margin: 0.3rem;
  }
}
