* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background: lightseagreen;
  font-family: "Poppins", sans-serif;
  min-height: 100vh;
}
.App {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: auto;
}
