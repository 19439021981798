$shadow: 3px 3px 10px rgba(0, 0, 0, 0.4),
  -3px -3px 10px rgba(255, 255, 255, 0.3);
$br: 0.5rem;

.fruitStyle {
  margin: 10vh auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35%, 1fr));
  gap: 0.5rem;
  width: 60%;
  height: 60vh;
  perspective: 5000px;
}
.fruitBox {
  box-shadow: $shadow;
  border-radius: $br;
  display: grid;
  place-items: center;
  transition: all 250ms ease;

  a {
    text-decoration: none;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: bold;
    color: green;
  }

  img {
    max-width: 90%;
    box-shadow: 1px 1px 5px rgba(107, 103, 103, 0.349);
  }

  &:hover {
    transform: translateZ(2rem);
    box-shadow: 3px 3px 17px rgba(0, 0, 0, 0.3),
      -3px -3px 17px rgba(255, 255, 255, 0.3);
  }
}
