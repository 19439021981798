$shadow: 3px 3px 10px rgba(0, 0, 0, 0.3),
	-2px -2px 10px rgba(255, 255, 255, 0.3);

$bd-coral: 1px solid coral;
$bd-yellow: 1px solid rgb(255, 255, 0);

$backgroundColor: rgb(219, 210, 231);
$blackbcg: rgb(87, 83, 83);
$greenbcg: rgb(16, 119, 16);
$redbcg: rgb(201, 82, 82);
$bluebcg: rgb(143, 143, 228);
$yellowbcg: rgb(241, 241, 3);
$blackHue: rgba(0, 0, 0, 0.8);
$myGreen: #2a5a24;
$myBlue: #298c93;
$myYellow: #bf8c3f;
$myYellow2: #f0bb0e;
$myYellow3: #ffc600;
$myRed: #c14242;
$btnColor: cadetblue;

$myGrey: rgba(78, 72, 72, 0.87);
$mybg: rgb(39, 19, 19);

$w: 100%;
$h: 100vh;
$fs: 20px;

$t: all 2s ease;

$border: 0.6rem;

$black: 1px solid black;
$red: 1px solid red;
$white: 1px solid white;
$blue: 1px solid blue;
$green: 1px solid green;

$primaryColor: cadetblue;
$secondaryColor: rgba(255, 255, 255, 0.15);
$tertiaryColor: rgba(235, 225, 225, 0.6);
$navBarBgColor: rgba(26, 59, 87, 0.7);
