@use "css" as *;

$clamp: clamp(0.7rem, 2vw, 1.5rem);

.control {
  @include fEven(column);
  width: $w;
  height: $w;
  border-radius: $border;
  padding: 0 0.4rem;
  background-color: lighten($secondaryColor, 80%);
  transition: background-color 100ms ease-in-out;

  h2 {
    color: rgb(216, 206, 206);
  }

  &:hover {
    background-color: $tertiaryColor;
  }

  p {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 0.3rem;
    font-size: $clamp;
    @include fBetween(row);
    width: $w;
    transition: color 100ms ease-in-out;
    cursor: pointer;
    padding: 0 0.4rem;

    &:hover {
      color: rgba(238, 223, 223, 0.9);
    }

    span {
      color: darken($primaryColor, 40%);
      font-size: $clamp;
    }
  }
}
