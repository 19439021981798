$bd: 1px solid black;

.container {
  margin: 10vh auto;
  max-width: 90%;
  height: 60vh;
  max-height: 100vh;
  perspective: 1000px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0.5rem;
  padding: 2rem;
}

.addCardBtn {
  position: absolute;
  right: 0;
  top: 0;
  outline: none;
  border: none;
  background-color: darkred;
  color: white;
  padding: 5px 8px;
  border-radius: 3px;
  transition: all 100ms ease-in-out;

  &:hover {
    background-color: rgb(117, 19, 19);
    color: rgb(201, 192, 192);
  }

  &:active {
    transform: scale(0.97);
  }
}
